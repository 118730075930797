<template>
  <div>
    <h4 class="text-primary">Hello, {{ userObj.first_name }} 👋</h4>
    <p class="h5 mb-3 text-dark">Welcome to OneCentral Partners Dashboard</p>

    <CRow class="center justify-content-lg-between">
      <CCol sm="11" md="5" lg="5">
        <CRow class="align-items-center">
          <CCol sm="12">
            <CCard>
              <CCardHeader class="h5">
                Available Balance
                <div class="float-right">
                  <span class="h6">Show Balance</span>
                  <input
                    id="s2"
                    type="checkbox"
                    @click="toggleSwitch"
                    class="switch ml-2 mt-1"
                    checked
                  />
                </div>
              </CCardHeader>
              <CCardBody>
                <span ref="showBalance">
                  <span class="available-balance h3">
                    &#8358; {{ userObj.customer_balance }}
                  </span>
                </span>
                <h3 ref="hideBalance" class="d-none">
                  💰💰💰💰
                </h3>

                <CRow class="">
                  <CCol sm xs="12" class="text-center mt-3">
                    <CRow class=" justify-content-around">
                      <CButton
                        color="success"
                        @click="virtualAccountModal = true"
                        class="mr-1"
                        shape="pill"
                      >
                        <CIcon name="cil-credit-card" />&nbsp;Fund Wallet
                      </CButton>
                      <CLink
                        class="btn btn-primary p-1 px-2"
                        to="/transfer-send-money"
                        shape="pill"
                        style="border-radius: 50em;"
                      >
                        <CIcon name="cil-money" size="xl" />
                        Send Money
                      </CLink>
                    </CRow>
                  </CCol>
                  <!-- <CModal
                    :show.sync="darkModal"
                    :no-close-on-backdrop="true"
                    title="Fund Wallet"
                    size="lg"
                    color="primary"
                  >
                    <template #header>
                      <h6 class="modal-title">Fund Wallet</h6>
                      <CButtonClose
                        @click="darkModal = false"
                        class="text-white"
                      />
                    </template>

                    <CForm>
                      <CCardBody>
                        <CInput
                          placeholder="ex. 1.000.000"
                          append=".00"
                          type="text"
                          v-model.number="amount"
                          required
                        >
                          <template #prepend-content
                            ><CIcon name="cil-money"
                          /></template>
                        </CInput>
                      </CCardBody>
                    </CForm>
                    <template #footer>
                      <CButton @click="darkModal = false" color="danger"
                        >Go Back</CButton
                      >
                      <CButton
                        color="primary"
                        @click="initiateWallet"
                        :disabled="!formIsValid || fundLoading"
                      >
                        <span v-if="fundLoading">
                          <loader-icon
                            size="1.5x"
                            class="custom-class"
                          ></loader-icon>
                          loading...
                        </span>
                        <span v-else>Pay</span>
                      </CButton>
                    </template>
                  </CModal> -->

                  <!-- <CModal
                    :show.sync="bankModal"
                    :no-close-on-backdrop="true"
                    title="Fund Wallet"
                    size="lg"
                    color="primary"
                  >
                    <template #header>
                      <h6 class="modal-title">Fund Wallet</h6>
                      <CButtonClose
                        @click="bankModal = false"
                        class="text-white"
                      />
                    </template>

                    <CForm class="border" v-if="bankTransferDetails">
                      <CCardHeader class="text-center h4">
                        <strong>{{
                          bankTransferDetails.response_message
                        }}</strong>
                      </CCardHeader>

                      <CInput
                        class="text-center h6"
                        label="Account Name"
                        :value="bankTransferDetails.data.account_name"
                        horizontal
                        plaintext
                      />
                      <CInput
                        class="text-center h6"
                        label="Bank Name"
                        :value="bankTransferDetails.data.bank_name"
                        horizontal
                        plaintext
                      />
                      <CInput
                        class="text-center h6"
                        label="Account Number"
                        :value="bankTransferDetails.data.account_number"
                        horizontal
                        plaintext
                      />
                    </CForm>

                    <template #footer>
                      <CButton @click="bankModal = false" color="danger"
                        >Go Back</CButton
                      >
                      <CButton
                        color="primary"
                        @click="confirmPaymentWithTransfer"
                      >
                        <span v-if="bankLoading">
                          <loader-icon
                            size="1.5x"
                            class="custom-class"
                          ></loader-icon>
                          loading...
                        </span>
                        <span v-else>I Have Made The Transfer</span>
                      </CButton>
                    </template>
                  </CModal> -->

                  <CModal
                    :show.sync="virtualAccountModal"
                    :no-close-on-backdrop="true"
                    title="Fund Wallet"
                    size="lg"
                    color="primary"
                  >
                    <template #header>
                      <h6 class="modal-title">Fund Wallet</h6>
                      <CButtonClose
                        @click="virtualAccountModal = false"
                        class="text-white"
                      />
                    </template>
                    <template #default>
                      <p>
                        Make a bank transfer to this account details below and
                        your onecentral wallet gets credited instantly with zero
                        charges.
                      </p>
                      <CRow class="align-items-center">
                        <CCol sm="6" class="py-2 font-lg font-weight-bold">
                          Bank Name:
                        </CCol>
                        <CCol sm="6" class="py-2 font-lg font-weight-normal">
                          {{ virtualAcountDetails.bank_name }}
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol sm="6" class="py-2 font-lg font-weight-bold">
                          Account Name:
                        </CCol>
                        <CCol sm="6" class="py-2 font-lg font-weight-normal">
                          {{ virtualAcountDetails.account_name }}
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol sm="6" class="py-2 font-lg font-weight-bold">
                          Account Number:
                        </CCol>
                        <CCol sm="6" class="py-2 font-lg font-weight-normal">
                          {{ virtualAcountDetails.account_number }}
                        </CCol>
                      </CRow>
                    </template>
                    <template #footer>
                      <CButton
                        @click="virtualAccountModal = false"
                        color="danger"
                        >Go Back</CButton
                      >
                    </template>
                  </CModal>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
          <!-- Aggregator codes -->
          <CCol sm="12" class="mt-2" v-if="userObj.agent.code">
            <!-- The new aggregator -->
            <CCard>
              <CCardHeader class="h4">
                <strong>Aggregator Key</strong>
              </CCardHeader>
              <CCardBody>
                <CForm class="h5">
                  <!-- The aggreagator -->
                  <CInput
                    label="Aggregator Key"
                    :value="userObj.agent.code"
                    horizontal
                    plaintext
                    id="Aggregator"
                  >
                    <template #append>
                      <CButton color="btn" @click="copyKey('Aggregator')">
                        <i class="far fa-copy"></i>
                      </CButton>
                    </template>
                  </CInput>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CCol>

      <!-- Api keys and Webhook -->
      <CCol sm="11" md="6" lg="5" v-if="userObj.agent.type === 'api-merchant'">
        <CCard>
          <CCardHeader class="h6">
            <strong>Api Keys & Webhooks</strong>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="generateKey" class="h6">
              <CInput
                v-if="userObj.agent.api_private_key"
                label="Private Test Key"
                :value="userObj.agent.api_private_key"
                horizontal
                plaintext
                :type="privateFieldType"
                add-wrapper-classes="sam"
                id="Private"
              >
                <template #append>
                  <CButton
                    color="btn"
                    v-if="privateFieldType === 'text'"
                    @click="copyKey('Private')"
                  >
                    <i class="far fa-copy"></i>
                  </CButton>
                  <CButton class="btn" @click="switchVisibility">
                    <i :class="privateEyeIcon"></i>
                  </CButton>
                </template>
              </CInput>
              <CInput
                v-else
                label="Private Test Key"
                value="Click Below To Generate Api Key"
                horizontal
                plaintext
              >
              </CInput>
              <CInput
                v-if="userObj.agent.api_public_key"
                label="Public Test Key"
                :value="userObj.agent.api_public_key"
                horizontal
                plaintext
                id="Public"
              >
                <template #append>
                  <CButton color="btn" @click="copyKey('Public')">
                    <i class="far fa-copy"></i>
                  </CButton>
                </template>
              </CInput>
              <CInput
                v-else
                label="Public Test Key"
                value="Generate Key Below"
                horizontal
                plaintext
              >
              </CInput>
              <CInput
                v-if="userObj.agent.api_webhook_key"
                label="webhook Key"
                :value="userObj.agent.api_webhook_key"
                horizontal
                plaintext
                id="Webhook"
              >
                <template #append>
                  <CButton color="btn" @click="copyKey('Webhook')">
                    <i class="far fa-copy"></i>
                  </CButton>
                </template>
              </CInput>
              <CInput
                v-else
                label="webhook Key"
                value="Coming Soon..."
                horizontal
                plaintext
              >
              </CInput>

              <CButton
                color="primary"
                class="px-3 float-right py-3 font-sm"
                type="submit"
                :disabled="loading"
                size="sm"
              >
                <span v-if="loading"
                  ><loader-icon size="1.5x" class="custom-class"></loader-icon
                  >Please Wait</span
                >
                <span v-else>Generate New Api Key</span>
              </CButton>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <!-- Cards -->
    <CRow v-if="cards" class="justify-content-around">
      <CCol sm="6" md="5" lg="3">
        <CCard color="primary">
          <CCardBody class="flex-row justify-content-between text-secondary">
            <CRow class="align-items-center justify-content-around">
              <div>
                <h4>Total Amount</h4>
                <h6>&#8358; {{ cards.total_amount }}</h6>
              </div>
              <div>
                <CIcon name="cil-money" size="xl" />
              </div>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol sm="6" md="5" lg="3">
        <CCard color="primary" class="">
          <CCardBody class="flex-row justify-content-between text-secondary">
            <CRow class="align-items-center justify-content-around">
              <div>
                <h4>Total Commission</h4>
                <h6>&#8358; {{ cards.total_commission }}</h6>
              </div>
              <div>
                <CIcon name="cil-money" size="xl" />
              </div>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol sm="6" md="5" lg="3">
        <CCard color="primary">
          <CCardBody class="flex-row justify-content-between text-secondary">
            <CRow class="align-items-center justify-content-around">
              <div>
                <h4>Total Volume</h4>
                <h6>{{ cards.total_volume }}</h6>
              </div>
              <div>
                <CIcon name="cil-money" size="xl" />
              </div>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "../services/api.service";
import Swal from "sweetalert2";

export default {
  name: "Dashboard",
  computed: {
    ...mapGetters(["userObj"]),
    formIsValid() {
      return this.amount >= 1;
    },
    loading() {
      return this.$store.getters.loading;
    },
    virtualAcountDetails() {
      return this.$store.getters.userObj.virtual_account;
    },
  },
  data() {
    return {
      amount: 0,
      showBalance: true,
      virtualAccountModal: false,
      darkModal: false,
      bankModal: false,
      selected: "Month",
      isModalVisible: false,
      isHistoryModalVisible: false,
      bankTransferModal: false,
      isModalVisible2: false,
      reference_number: "P0090",
      fundLoading: false,
      bankLoading: false,
      histories: [],
      no_history: "",
      loadHistory: false,
      transact_id: "",
      transact_reference: "",
      readables: [],
      transactionDets: null,
      bankTransferDetails: null,
      transaction_status: "",
      printReceiptLoading: false,
      receiptURL: null,
      cards: null,
      privateFieldType: "password",
      privateEyeIcon: "fas fa-eye-slash",
    };
  },
  methods: {
    switchVisibility() {
      this.privateFieldType =
        this.privateFieldType === "password" ? "text" : "password";
      this.privateEyeIcon =
        this.privateEyeIcon === "fas fa-eye-slash"
          ? "far fa-eye"
          : "fas fa-eye-slash";
    },
    async initiateWallet() {
      this.bankTransferDetails = null;
      this.fundLoading = true;

      let requestData = {
        amount: this.amount,
      };

      try {
        const response = await ApiService.post(
          "/transaction/inititate-wallet-load",
          requestData
        );
        console.log(response);
        this.reference_number = response.data.data.transaction.reference;
        let data = {
          reference: this.reference_number,
        };
        this.getDynamicAccount(data);
      } catch (error) {
        console.log(error.response);
        this.fundLoading = false;
        this.amount = 0;
      }
    },
    async getDynamicAccount(data) {
      try {
        const response = await ApiService.post(
          "/service/get-dynamic-account",
          data
        );
        console.log(response);
        this.fundLoading = false;
        this.darkModal = false;
        this.amount = 0;
        this.bankTransferDetails = response.data;
        console.log(this.bankTransferDetails);

        this.bankModal = true;
      } catch (error) {
        console.log(error.response);
      }
    },
    confirmPaymentWithTransfer() {
      this.checkPayment(true);
    },
    async checkPayment(paymentClicked = null) {
      // this.bankLoading = true
      let data = {
        reference: this.reference_number,
      };
      try {
        const response = await ApiService.post(
          "/transaction/check-bank-trasfer-hook",
          data
        );

        console.log(response);
        this.bankModal = false;

        Swal.fire({
          title: "Success",
          text: response.data.response_message,
          icon: "success",
          timer: 3000,
          confirmButtonText: "Close",
          showConfirmButton: false,
        });
        this.$store.dispatch("getCustomerWallet");
      } catch (error) {
        let _error = error.response;
        console.log(_error);

        if (paymentClicked === true) {
          Swal.fire({
            title: "Error!",
            text: _error.data.response_message,
            icon: "error",
          });
        }
      }
    },
    switchBalance() {
      this.showBalance = !this.showBalance;
    },
    toggleSwitch() {
      this.$refs.showBalance.classList.toggle("d-none");
      this.$refs.hideBalance.classList.toggle("d-block");
    },
    async statCards() {
      try {
        const response = await ApiService.get("/partners/stats");
        //console.log(response)
        this.cards = response.data.data;
      } catch (error) {
        console.log(error.response);
      }
    },
    generateKey() {
      this.$store.dispatch("generateApiKey");
    },
    copyKey(name) {
      let copyText = document.getElementById(name);
      copyText.select();
      document.execCommand("copy");

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        //background:"#00000",
        //background: '#000',
        timer: 1600,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        //title: `${name} Key Copied Successfully`,
        title: `<p class="text-light font-weight-bold">${name} Key Copied Successfully</p>`,
        //icon: 'info',
        background: "#a1dba1",
      });
    },
  },
  mounted() {
    this.$store.dispatch("getCustomerWallet"), this.statCards();
  },
  watch: {
    bankTransferDetails(val) {
      // let timeOut
      let numberOfCheckPaymentCall = 0;
      let checkPaymentCallLimit = 12; // for 120 seconds (2 mins)
      if (val && !this.t) {
        this.t = setInterval(() => {
          if (numberOfCheckPaymentCall <= checkPaymentCallLimit) {
            this.checkPayment(false);
            numberOfCheckPaymentCall++;
            console.log(numberOfCheckPaymentCall);
          }
        }, 10000);
        // console.log("gmakkkks");
      } else {
        clearInterval(this.t);
        //this.bankTransferDetails = null;
        // console.log("out of here");
      }
    },
  },
};
</script>

<style scoped>
.sam input {
  background-color: red;
}

input[type="text"]:active {
  outline: none !important;
  border: 0 !important;
}
input[type="checkbox"].switch {
  width: 38px;
  border-radius: 11px;
}

input[type="checkbox"] + label {
  font-size: 14px;
  line-height: 21px;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  margin-left: 4px;
}

input[type="checkbox"].switch:checked {
  --ab: var(--active-inner);
  --x: 17px;
}

input[type="checkbox"].switch {
  width: 38px;
  border-radius: 11px;
}

input[type="checkbox"]:checked {
  --b: var(--active);
  --bc: var(--active);
  --d-o: 0.3s;
  --d-t: 0.6s;
  --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
}

input[type="checkbox"] {
  --active: #5296ca;
  --active-inner: #fff;
  --focus: 2px rgba(39, 94, 254, 0.3);
  --border: #bbc1e1;
  --border-hover: #5296ca;
  --background: #fff;
  --disabled: #f6f8ff;
  --disabled-inner: #e1e6f9;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 21px;
  outline: none;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0;
  cursor: pointer;
  border: 1px solid var(--bc, var(--border));
  background: var(--b, var(--background));
  transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
}

input[type="checkbox"].switch:after {
  left: 2px;
  top: 2px;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background: var(--ab, var(--border));
  transform: translateX(var(--x, 0));
}

input[type="checkbox"]:after,
input[type="radio"]:after {
  content: "";
  display: block;
  left: 0;
  top: 0;
  position: absolute;
  transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
    opacity var(--d-o, 0.2s);
}

.absolute {
  position: absolute;
  top: 0;
  right: 0;
}

.btn:active,
.btn:focus,
.btn:focus-visible,
.btn:focus-within {
  outline: 0 !important;
  border: 0 !important;
}
</style>
